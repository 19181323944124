import { CouponEffects, CouponRequirements } from "../constants/Coupons";
import Coupon from "../interfaces/coupon/Coupon";
import CouponEffect from "../interfaces/coupon/CouponEffect";
import CouponOptionProperty from "../interfaces/coupon/CouponOptionProperty";
import CouponRequirement from "../interfaces/coupon/CouponRequirement";

class CouponsHelper {
    static extractCouponsCode(coupons: Coupon[]) {
        return coupons.map((coupon) => {
            return coupon.code;
        });
    }

    static parsePropString(type: string, string: string) {
        const parsedArray = string.split(/[([\])]/).filter(val => val !== '');
        if (type === 'effect') {
            let arrayIndex = 0;
            let couponArr = <CouponEffect[]>[];
            while (arrayIndex < parsedArray.length) {
                const effect = CouponEffects.find(val => val.key === parsedArray[arrayIndex]);
                if (effect) {
                    let couponEffect = <CouponEffect>{
                        type: effect.key
                    };
                    let props = <CouponOptionProperty[]>[];
                    effect.properties.forEach((prop) => {
                        props.push({
                            ...prop,
                            value: parsedArray[++arrayIndex]
                        });
                    });
                    couponEffect.properties = props;
                    couponArr.push(couponEffect);
                }
                arrayIndex++;
            }
            return couponArr;

        }
        if (type === 'requirement') {
            let arrayIndex = 0;
            let couponArr = <CouponRequirement[]>[];
            while (arrayIndex < parsedArray.length) {
                const requirement = CouponRequirements.find(val => val.key === parsedArray[arrayIndex]);
                if (requirement) {
                    let couponRequirement = <CouponRequirement>{
                        type: requirement.key
                    };
                    let props = <CouponOptionProperty[]>[];
                    requirement.properties.forEach((prop) => {
                        props.push({
                            ...prop,
                            value: parsedArray[++arrayIndex]
                        });
                    });
                    couponRequirement.properties = props;
                    couponArr.push(couponRequirement);
                }
                arrayIndex++;
            }
            return couponArr;
        }
    }

    static generatePropString(couponArr: CouponEffect[] | CouponRequirement[]): string {
        let outputString = '';
        couponArr.forEach(value => {
            outputString += '(';
            outputString += `[${value.type}]`;
            value.properties.forEach(prop => {
                outputString += `[${prop.value}]`;
            });
            outputString += ')';
        });
        return outputString;
    }
}

export default CouponsHelper;