import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Coupon from 'src/app/interfaces/coupon/Coupon';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CouponService {
  ApiUrl: string;

  HttpHeaders = new HttpHeaders({
    accept: 'application/json, text/plain, */*',
    'Cache-Control': 'no-cache'
  });

  constructor(
    private httpClient: HttpClient
  ) {
    this.ApiUrl = `${environment.apiUrl}/planted`;
  }

  getAllCoupons() {
    return this.httpClient.get<Coupon[]>(`${this.ApiUrl}/coupons`, { headers: this.HttpHeaders });
  }

  updateCoupon(obj) {
    const coupon = obj as Coupon;
    if (coupon.expiration_date === '0') {
      console.log('Converting Expiration Date');
      coupon.expiration_date = null;
    }

    return this.httpClient.put(`${this.ApiUrl}/coupons`, obj, { headers: this.HttpHeaders });
  }
}
