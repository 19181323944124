import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { CouponEffects, CouponRequirements } from '../constants/Coupons';
import CouponStateModel from '../interfaces/store/CouponStateModel';
import { CouponService } from '../services/coupon/coupon.service';
// import * as moment from 'moment/moment';
import CouponsHelper from '../helpers/CouponsHelper';
import { InventoryService } from '../services/inventory/inventory.service';
import { fetchInventoryItems } from './inventory.state';
import InventoryItem from '../interfaces/inventory/InventoryItem';

export class fetchCoupons {
  static readonly type = '[CouponState] fetchCoupons';

  constructor() { }
}

export class fetchVariantSuggestions {
  static readonly type = '[CouponState] fetchVariantSuggestions';

  constructor() { }
}

export class setStateUpdateDate {
  static readonly type = '[CouponState] setStateUpdateDate';

  constructor(
    public d: Date
  ) { }
}

@State<CouponStateModel>({
  name: 'CouponState',
  defaults: {
    Coupons: [],
    EffectOptions: CouponEffects,
    RequirementOptions: CouponRequirements,
    CouponCodes: [],
    VariantSuggestions: [],
    StateLoadDate: null
  }
})
@Injectable()
export class CouponState {

  constructor(
    private couponService: CouponService,
    private store: Store
  ) { }

  @Action(fetchCoupons)
  async fetchCoupons(context: StateContext<CouponStateModel>, action: fetchCoupons) {
    const state = context.getState();
    let coupons = await this.couponService.getAllCoupons().toPromise();
    // Date patch
    coupons.map((coupon) => {
      if (coupon.expiration_date) {
        const expiryArr = coupon.expiration_date.split('T');
        const expiryDateTest = new Date(`${expiryArr[0]}:${expiryArr[1]}`);
        if (!this.isDateValid(expiryDateTest)) coupon.expiration_date = null;
        else coupon.expiration_date = new Date(`${expiryArr[0]}:${expiryArr[1]}`).toISOString();
      }

      if (coupon.start_date) {
        const startArr = coupon.start_date.split('T');
        const startDateTest = new Date(`${startArr[0]}:${startArr[1]}`);
        if (!this.isDateValid(startDateTest)) coupon.start_date = null;
        else coupon.start_date = new Date(`${startArr[0]}:${startArr[1]}`).toISOString();
      }
    });
    // Variant Suggestions hook
    const variantSuggestion = await this.generateVariantSuggestions();

    context.setState({
      ...state,
      Coupons: coupons,
      CouponCodes: CouponsHelper.extractCouponsCode(coupons),
      VariantSuggestions: variantSuggestion
    });
  }

  @Action(fetchVariantSuggestions)
  async fetchVariantSuggestions(context: StateContext<CouponStateModel>, action: fetchVariantSuggestions) {
    const state = context.getState();
    const arr = await this.generateVariantSuggestions();
    context.setState({
      ...state,
      VariantSuggestions: arr
    });
  }

  private isDateValid(d: Date) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {  // d.valueOf() could also work
        // date is not valid
        return false;
      } else {
        // Check for garbage
        if (d.toISOString().includes('2001-01-01')) {
          return false;
        }
        // date is valid
        return true;
      }
    } else {
      // not a date
      return false;
    }
  }

  private async generateVariantSuggestions() {
    let arr = <{ variant_id: string, product_name: string }[]>[];

    // Fetch Inventory
    // const inventoryItems = await this.inventoryService.get_all_inventory().toPromise();
    let inventoryItems = this.store.selectSnapshot(store => store.InventoryState.InventoryItems);
    if (inventoryItems.length === 0) {
      await this.store.dispatch(new fetchInventoryItems()).toPromise<InventoryItem[]>();
      inventoryItems = this.store.selectSnapshot(store => store.InventoryState.InventoryItems);
    }

    // Extract Variant Details
    inventoryItems.forEach((item) => {
      if (item.name || item.display_name) {
        item.variants.forEach((variant) => {
          arr.push({
            product_name: item.name ? item.name : item.display_name,
            variant_id: variant.variant_id
          });
        });
      }
    });

    return arr;
  }

  @Action(setStateUpdateDate)
  setStateUpdateDate(context: StateContext<CouponStateModel>, action: setStateUpdateDate) {
    const state = context.getState();
    context.setState({
      ...state,
      StateLoadDate: action.d
    });
  }
}
