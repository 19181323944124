import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import ApplicationStateModel from '../interfaces/store/ApplicationStateModel';

export class setTitle {
    static readonly type = '[ApplicationState] setTitle';

    constructor(public value: string) { }
}

export class showSpinner {
    static readonly type = '[ApplicationState] showSpinner';

    constructor(public value: boolean) { }
}

export class showProgressBar {
    static readonly type = '[ApplicationState] showProgressBar';

    constructor(public value: boolean) { }
}

export class setProgressBarPercentage {
    static readonly type = '[ApplicationState] setProgressBarPercentage';

    constructor(public value: number) { }
}

@State<ApplicationStateModel>({
    name: 'ApplicationState',
    defaults: {
        ApplicationTitle: environment.applicationTitle,
        ApiURL: environment.apiUrl,
        ShowSpinner: false,
        ProgressBar: { ShowProgressBar: false, ProgressBarPercentage: 0 }
    }
})
@Injectable()
export class ApplicationState {
    @Action(setTitle)
    setTitle(context: StateContext<ApplicationStateModel>, action: setTitle) {
        const state = context.getState();

        context.setState({
            ...state,
            ApplicationTitle: action.value
        });
    }

    @Action(showSpinner)
    showSpinner(context: StateContext<ApplicationStateModel>, action: showSpinner) {
        const state = context.getState();

        context.setState({
            ...state,
            ShowSpinner: action.value
        });
    }

    @Action(showProgressBar)
    showProgressBar(context: StateContext<ApplicationStateModel>, action: showProgressBar) {
        const state = context.getState();

        context.setState({
            ...state,
            ProgressBar: { ShowProgressBar: action.value, ProgressBarPercentage: 0 }
        });
    }

    @Action(setProgressBarPercentage)
    setProgressBarPercentage(context: StateContext<ApplicationStateModel>, action: setProgressBarPercentage) {
        const state = context.getState();

        context.setState({
            ...state,
            ProgressBar: { ...state.ProgressBar, ProgressBarPercentage: action.value }
        });
    }
}

