import { Component, OnDestroy } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { showSpinner } from 'src/app/store/application.state';
import { environment } from '../environments/environment';
import { DataService } from './services/data/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  title$: Observable<string>
  showSpinner$: Observable<boolean>
  progressBar$: Observable<{ ShowProgressBar: Boolean, ProgressBarPercentage: number }>;

  constructor(
    private store: Store,
    private router: Router,
    private dataService: DataService
  ) {
    this.title$ = this.store.select(state => state.ApplicationState.ApplicationTitle);
    this.showSpinner$ = this.store.select(state => state.ApplicationState.ShowSpinner);
    this.progressBar$ = this.store.select(state => state.ApplicationState.ProgressBar);
    console.log(`Release Version ${environment.releaseVersion}`);

    router.events.subscribe((routerEvent: RouterEvent) => {
      this.handleRouterEvent(routerEvent);
    });

    dataService.startTimer();
  }
  ngOnDestroy(): void {
    this.dataService.clearTimer();
  }

  handleRouterEvent(routerEvent: RouterEvent) {
    if (routerEvent instanceof NavigationStart) {
      this.store.dispatch(new showSpinner(true));
    }

    if (routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError) {
      this.store.dispatch(new showSpinner(false));
    }
  }
}
