<mat-dialog-content class="custom-card ">
        
    <div class="d-flex justify-content-center h-100vh w-100w">
        <div class="col-12">
            <div class="card custom-card">
                <div class="card-body p-0">
                    <h1 class="text-center">Are you sure?</h1>
                    <div class="d-flex justify-content-center mt-4">
                        <button class="btn add-product-btn px-4 mx-4" (click)="yesHandler()">Confirm</button>
                        <button class="btn add-product-btn px-4 mx-4" mat-button [mat-dialog-close]="false">Cancel</button>
                    </div>
                </div>
                </div>
            </div>
        </div>
</mat-dialog-content>