<mat-dialog-content>
  <div class="progress-bar-container">
    <div class="progress-bar-center">
      <h6 class="text-center">Operation in Progress</h6>
      <div class="progress">
        <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar"
          [attr.aria-valuenow]="ProgressPercentage" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
      </div>
    </div>

  </div>
</mat-dialog-content>