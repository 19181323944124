import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Social from 'src/app/interfaces/social/social';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocialService {

  ApiUrl: string;

  HttpHeaders = new HttpHeaders({
    accept: 'application/json, text/plain, */*',
    'Cache-Control': 'no-cache'
  });

  constructor(
    private httpClient: HttpClient
  ) {

    this.ApiUrl = `${environment.apiUrl}/planted/htmlmetadata`;
    // this.ApiUrl = "https://jsonplaceholder.typicode.com/users"
  }

  getSocial() {
    // return this.httpClient.get<Social[]>(`${this.ApiUrl}/htmlmetadata`, { headers: this.HttpHeaders });

    return this.httpClient.get<Social[]>(`${this.ApiUrl}`, { headers: this.HttpHeaders });
  }


  updateSocial(socialData){

    return this.httpClient.put<Social[]>(`${this.ApiUrl}`, socialData, { headers: this.HttpHeaders });
  }

  addSocial(info){
    return this.httpClient.post<Social[]>(`${this.ApiUrl}`, info, { headers: this.HttpHeaders });
  }
}
