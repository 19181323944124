import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from 'src/app/services/auth/token.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private tokenService: TokenService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Authenticate user
    // If no token, redirect to login
    if (!this.tokenService.Check()) {
      // Redirect to login here...
      this.tokenService.redirect();
      return false;
    }
    // Token exists, check expiry
    // If expired, redirect to login
    if (this.tokenService.isExpired()) {
      // Redirect to login here...
      this.tokenService.redirect();
      return false;
    }
    return true;
  }

}
