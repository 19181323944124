import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import IdsHelper from 'src/app/helpers/IdsHelper';
import { showSpinner } from 'src/app/store/application.state';
import { fetchCoupons, setStateUpdateDate as setCouponStateUpdateDate } from 'src/app/store/coupon.state';
import { fetchCategories, setIdsUnique, setInventoryItems, setStateUpdateDate as setInventoryStateUpdateDate } from 'src/app/store/inventory.state';
import { FetchSocialDefaults, setStateUpdateDate as setSocialStateUpdateDate } from 'src/app/store/social.state';
import { InventoryService } from '../inventory/inventory.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  timerObject: any;

  constructor(
    private store: Store,
    private inventoryService: InventoryService
  ) { }

  async loadInventoryState() {
    // Init Inventory
    await this.store.dispatch(new showSpinner(true));
    const inventoryItems = await this.inventoryService.get_all_inventory().toPromise();
    await this.store.dispatch(new setInventoryItems(inventoryItems)).toPromise();
    await this.store.dispatch(new setIdsUnique(IdsHelper.getInventoryItemsIds(inventoryItems), IdsHelper.getVariantIds(inventoryItems)));
    // Setup Inventory categories
    await this.store.dispatch(new fetchCategories).toPromise();
    await this.store.dispatch(new setInventoryStateUpdateDate(new Date)).toPromise();
    await this.store.dispatch(new showSpinner(false));
  }

  async loadCouponState() {
    await this.store.dispatch(new showSpinner(true));
    await this.store.dispatch(new fetchCoupons).toPromise();
    await this.store.dispatch(new setCouponStateUpdateDate(new Date)).toPromise();
    await this.store.dispatch(new showSpinner(false));
  }

  async loadSocialState() {
    await this.store.dispatch(new showSpinner(true));
    await this.store.dispatch(new FetchSocialDefaults).toPromise();
    await this.store.dispatch(new setSocialStateUpdateDate(new Date)).toPromise();
    await this.store.dispatch(new showSpinner(false));
  }

  startTimer() {
    if (this.timerObject) {
      clearInterval(this.timerObject);
      this.timerObject = null;
    }

    this.timerObject = setInterval(() => {
      const currentDate = new Date;
      // Inventory state reload.
      let StateLoadDate = this.store.selectSnapshot<Date>(store => store.InventoryState.StateLoadDate);

      if (StateLoadDate != null) {
        const diffMs = (currentDate.getTime() - StateLoadDate.getTime());
        if (Math.round(diffMs / 60000) >= 5) {
          this.loadInventoryState();
        }
      }

      // Coupon state reload.
      StateLoadDate = this.store.selectSnapshot<Date>(store => store.CouponState.StateLoadDate);
      if (StateLoadDate != null) {
        const diffMs = (currentDate.getTime() - StateLoadDate.getTime());
        if (Math.round(diffMs / 60000) >= 5) {
          this.loadCouponState();
        }
      }

      // Social state reload.
      StateLoadDate = this.store.selectSnapshot<Date>(store => store.SocialState.StateLoadDate);
      if (StateLoadDate != null) {
        const diffMs = (currentDate.getTime() - StateLoadDate.getTime());
        if (Math.round(diffMs / 60000) >= 5) {
          this.loadSocialState();
        }
      }
    }, 2000 * 60);
  }

  clearTimer() {
    if (this.timerObject) {
      clearInterval(this.timerObject);
      this.timerObject = null;
    }
  }
}
