import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Store } from '@ngxs/store';
import { from, Observable } from 'rxjs';
import { DataService } from 'src/app/services/data/data.service';
import IdsHelper from '../../helpers/IdsHelper';
import { InventoryService } from '../../services/inventory/inventory.service';
import { fetchCategories, setIdsUnique, setInventoryItems, setStateUpdateDate } from '../../store/inventory.state';

@Injectable({
  providedIn: 'root'
})
export class InventoryResolver implements Resolve<boolean> {

  constructor(
    private dataService: DataService,
    private store: Store
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return from(this.setupInventory());
  }

  private async setupInventory() {
    let StateLoadDate = this.store.selectSnapshot<Date>(store => store.InventoryState.StateLoadDate);
    const currentDate = new Date;
    if (StateLoadDate != null) {
      const diffMs = (currentDate.getTime() - StateLoadDate.getTime());
      if (Math.round(diffMs / 60000) < 5) {
        return true;
      }
    }
    try {
      await this.dataService.loadInventoryState();
      return true;

    } catch (error) {
      console.log(error);
      return false
    }
  }
}
