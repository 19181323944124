<nav class="navbar navbar-expand-md navbar-light p-4 p-lg-3 fixed-top">
    <a class="ml-2 logo" href="javascript:void(0)" routerLink='/'>
        <img style="width: 140px; height: 60px;" src="assets/images/logo.png" />
    </a>
    <button type="button" class="navbar-toggler ml-auto" data-toggle="collapse" data-target="#navbar-collapse">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbar-collapse">
        <div class="navbar-nav w-100 d-flex justify-content-center p-2">
            <a href="javascript:void(0)" routerLink='inventory' [routerLinkActive]="['active']" class="nav-item nav-link mx-2">Inventory</a>
            <a href="javascript:void(0)" routerLink='coupons' [routerLinkActive]="['active']" class="nav-item nav-link mx-2">Coupons</a>
            <a href="javascript:void(0)" routerLink='order' [routerLinkActive]="['active']" class="nav-item nav-link mx-2">Orders</a>
            <a href="javascript:void(0)" routerLink='social' [routerLinkActive]="['active']" class="nav-item nav-link mx-2">Social</a>
            <a href="javascript:void(0)" routerLink='config' [routerLinkActive]="['active']" class="nav-item nav-link mx-2">Configuration</a>
        </div>
    </div>
</nav>