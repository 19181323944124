import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { showSpinner } from 'src/app/store/application.state';
import { TokenService } from '../services/auth/token.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  constructor(
    private tokenService: TokenService,
    private routeSnapshot: ActivatedRoute,
    private router: Router,
    private store: Store
  ) {
    this.store.dispatch(new showSpinner(true));
    this.authenticate().then((result) => {
      if (result) {
        this.store.dispatch(new showSpinner(false));
        this.router.navigateByUrl('inventory/list');
      }

    }).catch(err => console.log(err));
  }

  authenticate() {
    return new Promise<boolean>((resolve, reject) => {
      this.routeSnapshot.queryParams.subscribe(params => {
        const holdover = params['holdOver'];
        this.tokenService.fetchToken(holdover).toPromise().then((token) => {
          this.tokenService.Set(token.value);
          resolve(true);
        }).catch(err => reject(err));
      });
    });
  }

  ngOnInit(): void {
  }

}
