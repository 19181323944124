import { IEnvironment } from './ienvironment';

export const environment: IEnvironment = {
  production: false,
  // apiUrl: 'https://localhost:44388',
  // apiUrl: 'https://localhost:5001',
  apiUrl: 'https://staging-demeter.planted-labs.com',
  s3Url: 'https://planted-assets.s3.us-east-2.amazonaws.com',
  s3BucketName: 'planted-assets',
  applicationTitle: 'Planted CMS - DEV',
  key: 'plttk',
  releaseVersion: 'SocialUAT Round 2',
  rootUrl: 'https://root.planted-labs.com',
  // rootUrl: 'http://localhost:4201',
  AZ: [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
    'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
  ]
};
