<div class="application-main">
  <app-header></app-header>
  <div class="content-main">
    <!-- <ng-template #mainContent>
      
    </ng-template> -->
    <router-outlet></router-outlet>
  </div>
  <app-loader *ngIf="(showSpinner$ | async)"></app-loader>
  <app-progress-bar *ngIf="(progressBar$ | async).ShowProgressBar"
    [ProgressPercentage]="(progressBar$ | async).ProgressBarPercentage">
  </app-progress-bar>
</div>