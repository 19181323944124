import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import IdsHelper from "src/app/helpers/IdsHelper";
import InventoryItem from "../interfaces/inventory/InventoryItem";
import InventoryStateModel from "../interfaces/store/InventoryStateModel";
import { InventoryService } from "../services/inventory/inventory.service";

export class setInventoryItems {
  static readonly type = '[InventoryState] setInventoryItems';

  constructor(
    public items: InventoryItem[]
  ) { }
}

export class fetchInventoryItems {
  static readonly type = '[InventoryState] fetchInventoryItems';

  constructor() { }
}

export class fetchCategories {
  static readonly type = '[InventoryState] fetchCategories';

  constructor() { }
}

export class setIdsUnique {
  static readonly type = '[InventoryState] setIdsUnique';

  constructor(
    public inventoryIds: string[],
    public variantIds: string[],
  ) { }
}

export class setStateUpdateDate {
  static readonly type = '[InventoryState] setStateUpdateDate';

  constructor(
    public d: Date
  ) { }
}

@State<InventoryStateModel>({
  name: 'InventoryState',
  defaults: {
    InventoryItems: [],
    Categories: [],
    InventoryIds: [],
    VariantIds: [],
    StateLoadDate: null
  }
})
@Injectable()
export class InventoryState {

  constructor(private inventoryService: InventoryService) { }

  @Action(setInventoryItems)
  setInventoryItems(context: StateContext<InventoryStateModel>, action: setInventoryItems) {
    const state = context.getState();

    context.setState({
      ...state,
      InventoryItems: action.items
    });
  }

  @Action(fetchInventoryItems)
  async fetchInventoryItems(context: StateContext<InventoryStateModel>, action: fetchInventoryItems) {
    const state = context.getState();
    const inventoryItems = await this.inventoryService.get_all_inventory().toPromise();
    context.setState({
      ...state,
      InventoryItems: inventoryItems,
      InventoryIds: IdsHelper.getInventoryItemsIds(inventoryItems),
      VariantIds: IdsHelper.getVariantIds(inventoryItems),
    });
  }

  @Action(fetchCategories)
  async fetchCategories(context: StateContext<InventoryStateModel>, action: fetchCategories) {
    const state = context.getState();
    const categories = await this.inventoryService.get_all_categories().toPromise();
    context.setState({
      ...state,
      Categories: categories
    });
  }

  // @Action(addCategory)
  // addCategory(context: StateContext<InventoryStateModel>, action: addCategory) {
  //     const state = context.getState();
  //     context.setState({
  //         ...state,
  //         Categories: [...state.Categories, { id: 'new', title: action.item }]
  //     });
  // }

  @Action(setIdsUnique)
  setIdsUnique(context: StateContext<InventoryStateModel>, action: setIdsUnique) {
    const state = context.getState();
    context.setState({
      ...state,
      InventoryIds: action.inventoryIds,
      VariantIds: action.variantIds
    });
  }

  @Action(setStateUpdateDate)
  setStateUpdateDate(context: StateContext<InventoryStateModel>, action: setStateUpdateDate) {
    const state = context.getState();
    context.setState({
      ...state,
      StateLoadDate: action.d
    });
  }

}
