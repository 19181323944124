import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { MatDialogModule } from "@angular/material/dialog";
import { LoaderComponent } from './loader/loader.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';



@NgModule({
  declarations: [ImageUploadComponent, ConfirmationDialogComponent, LoaderComponent, ProgressBarComponent],
  imports: [
    MatDialogModule
  ],
  exports: [
    CommonModule,
    ImageUploadComponent,
    LoaderComponent,
    ProgressBarComponent,
    MatSlideToggleModule,
    MatDialogModule,
  ]
})
export class SharedModule { }
