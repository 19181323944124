import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { TokenService } from '../services/auth/token.service';
import { environment } from 'src/environments/environment';

const AUTH_HEADER_KEY = 'Authorization';
const AUTH_PREFIX = 'Bearer';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private tokenService: TokenService) { }




    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.tokenService.Get();
        // Clone and add token to request
        let auth_req = null;
        if (token) {
            auth_req = req.clone(
                {
                    headers: req.headers.set(AUTH_HEADER_KEY, `${AUTH_PREFIX} ${token}`)
                }
            );
        }


        return next.handle(auth_req ? auth_req : req).pipe(
            catchError(this.handle_error_case)
        );

    }

    handle_error_case(error) {
        if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
                // Redirect to login here...
                this.tokenService.redirect();
            }
        }
        return throwError(error);
    }

}
