import { Injectable } from "@angular/core";
import { Action, State, StateContext, Store } from "@ngxs/store";
import { SocialService } from '../services/social/social.service';
import SocialStateModel from "../interfaces/store/SocialStateModel"
import { InventoryService } from "../services/inventory/inventory.service";
import { fetchInventoryItems } from "./inventory.state";
import InventoryItem from "../interfaces/inventory/InventoryItem";



export class FetchSocialDefaults {
  static readonly type = '[SocialState] SendSocialInfo';

  constructor() { }
}

export class setStateUpdateDate {
  static readonly type = '[SocialState] setStateUpdateDate';

  constructor(
    public d: Date
  ) { }
}

@State<SocialStateModel>({
  name: 'SocialState',
  defaults: {
    social: [],
    ProductSuggestions: [],
    StateLoadDate: null
  }
})
@Injectable()
export class SocialState {

  constructor(
    private SocialService: SocialService,
    private store: Store
  ) { }


  @Action(FetchSocialDefaults)
  async FetchSocialDefaults(context: StateContext<SocialStateModel>, action: FetchSocialDefaults) {
    const state = context.getState();
    const Social = await this.SocialService.getSocial().toPromise();

    const suggestions = await this.generateProductSuggestions();
    context.setState({
      ...state,
      social: Social,
      ProductSuggestions: suggestions
    });
  }

  @Action(setStateUpdateDate)
  setStateUpdateDate(context: StateContext<SocialStateModel>, action: setStateUpdateDate) {
    const state = context.getState();
    context.setState({
      ...state,
      StateLoadDate: action.d
    });
  }

  private async generateProductSuggestions() {
    let arr = <{ inventory_id: string, product_name: string }[]>[];

    // // Fetch Inventory
    // const inventoryItems = await this.inventoryService.get_all_inventory().toPromise();
    // Check if inventory state exists
    let inventoryItems = this.store.selectSnapshot(store => store.InventoryState.InventoryItems);
    if(inventoryItems.length === 0) {
      await this.store.dispatch(new fetchInventoryItems()).toPromise<InventoryItem[]>();
      inventoryItems = this.store.selectSnapshot(store => store.InventoryState.InventoryItems);
    }

    // Extract Variant Details
    inventoryItems.forEach((item) => {
      if (item.name || item.display_name) {
        arr.push({
          product_name: item.name ? item.name : item.display_name,
          inventory_id: item.inventory_id
        });
      }
    });

    return arr;
  }

}

