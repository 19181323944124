import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import SetImagePartsCase from 'src/app/enums/SetImagePartsCase';
import SetImagePartsEvent from 'src/app/interfaces/common/setImagePartsEvent';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {

  @Input('src')
  DefaultImage: string | ArrayBuffer;

  Image: string | ArrayBuffer;
  // ImageFile: File;

  @Input('case')
  Case: SetImagePartsCase;

  @ViewChild('image_input')
  imageInputRef: ElementRef<HTMLElement>

  @Output('onSetImageParts')
  setImagePartsEvent: EventEmitter<SetImagePartsEvent> = new EventEmitter();

  FileReader: FileReader = new FileReader();



  bufferPosition: number = 0;

  constructor(
  ) { }

  ngOnInit(): void {
  }

  public message: string;

  preview(files: File[]) {
    if (files.length === 0)
      return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    this.readFile(files[0]);
  }

  readFile(file: File) {
    // Setup Preview Image
    let reader = new FileReader();

    reader.onload = (_event) => {
      // Get Result
      const binaryFile = _event.target.result;
      this.Image = 'data:image/jpeg;base64,' + btoa(<string>binaryFile);;
      // Set preview here

      // Generate File name
      let fileName = `${file.name.split('.')[0].toLowerCase()}-${Date.now()}`;
      let fileExt = file.name.split('.')[1].toLowerCase()

      if (this.Case === SetImagePartsCase.ImageHover) fileName = fileName + '-hover';

      this.setImagePartsEvent.emit({
        case: this.Case,
        image: binaryFile,
        fileExt: fileExt,
        fileName: fileName
      });
    }
    reader.readAsBinaryString(file);
  }

  openFileDialogHandler() {
    this.imageInputRef.nativeElement.click();
  }

  get Title() {
    switch (this.Case) {
      case SetImagePartsCase.Image:
        return 'Upload Image';
      case SetImagePartsCase.ImageHover:
        return 'Upload Hover Image';
      case SetImagePartsCase.ImageMeta:
        return 'Upload Meta Image';
      default:
        break;
    }
  }
}
