import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private app = 'Root';

  constructor(private http: HttpClient) {
  }

  // async login(): Promise<string> {
  //   console.log('Fetching Token');
  //   let promise = new Promise<string>((resolve, reject) => {
  //     this.http.post(`${environment.api_url}/planted/auth/login`,
  //       { userName: 'StubAccountForDev', password: 'SayALittlePrayerForYou' }, { responseType: 'text' }).subscribe(
  //         resp => {
  //           console.log('Successfully retrieved Token');
  //           console.log(resp);
  //           const tokenResp = JSON.parse(resp) as TokenResponse;
  //           this.Set(tokenResp.token);
  //           resolve(tokenResp.token);
  //         },
  //         error => {
  //           this.Delete();
  //           reject(error)
  //           throw new Error('Could not retrieve token');
  //         });
  //   });
  //   return promise;
  // }

  fetchToken(holdover) {
    return this.http.post<any>(`${environment.apiUrl}/planted/auth/fetch`, { id: holdover, token: '' });
  }


  Get(): string {
    return localStorage.getItem(environment.key);
  }

  Set(token: string): void {
    localStorage.setItem(environment.key, token);
    console.log(this.app + ' DEV: Setting Token');
  }

  Delete(): void {
    console.log(this.app + ' PROD: Deleting Token');
    localStorage.removeItem(environment.key);
  }

  Check(): boolean {
    return localStorage.getItem(environment.key) != null;
  }

  isExpired(): boolean {
    const token = localStorage.getItem(environment.key);
    const decoded_token = jwt_decode(token) as any;
    return (decoded_token.exp * 1000) < Date.now();
  }

  redirect(): void {
    window.location.href = `${environment.rootUrl}?term=${window.location.protocol}//${window.location.host}/auth`;
  }
}
